export class CapsuleType {
  id: string;
  name: string;

  capsuleApiKey: string;
  description: string;
  imageUrl: string;
}

export enum WordpressDeploymentType {
  default = 'default',
  git = 'git',
}

export enum WordpressVersion {
  v60 = '6.0.3-php-8.1',
  v61 = '6.6.1-php-8.2',
  v62 = '6.2.2-php-8.1',
  v63 = '6.3.3-php-8.2',
  v64 = '6.4.4-php-8.2',
  v65 = '6.5.5-php-8.3',
  v66 = '6.6.1-php-8.3',
}
