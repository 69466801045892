import {
  createAction,
  createActionGroup,
  emptyProps,
  props,
} from '@ngrx/store';
import {
  Build,
  Capsule,
  Space,
  CapsuleCostsResponse,
  ConfigDto,
  CapsuleManifest,
  Backup,
  CapsuleApiRepo,
  Repo,
} from '../../models';

import { ProductRequestDto } from 'src/app/create-content/pages/create-capsule/models/product-request.dto';
import { BackupSchedule } from '../../models/backup-schedule';
import { Restore } from '../../models/restore';

// set capsule ingress enabled
export const setDeployBuild = createAction(
  '[Capsule] Set Deploy Build Initiated',
  props<{ space: Space; capsule: Capsule; build: Build }>()
);
export const setDeployBuildSuccess = createAction(
  '[Capsule] Set Deploy Build Success',
  props<{ space: Space; capsule: Capsule; build: Build }>()
);
export const setDeployBuildFailed = createAction(
  '[Capsule] set Deploy Build Failed',
  props<{ error: Error }>()
);

// start capsule manual backup
export const setCapsuleManualBackup = createAction(
  '[Capsule] Set Capsule Manual Backup Initiated',
  props<{ spaceId: string; capsuleId: string }>()
);
export const setCapsuleManualBackupSuccess = createAction(
  '[Capsule] Set Capsule Manual Backup Success',
  props<{ backup: Backup }>()
);
export const setCapsuleManualBackupFailed = createAction(
  '[Capsule] Set Capsule Manual Backup Failed',
  props<{ error: Error }>()
);

// delete capsule Backup
export const deleteCapsuleBackup = createAction(
  '[Capsule] Delete capsule backup started',
  props<{ spaceId: string; capsuleId: string; backupId: string }>()
);
export const deleteCapsuleBackupSuccess = createAction(
  '[Capsule] Delete capsule backup succeeded',
  props<{ backupId: string }>()
);
export const deleteCapsuleBackupFailed = createAction(
  '[Capsule] Delete capsule backup failed',
  props<{ error: Error }>()
);

// set backup restore
export const startCapsuleBackupRestore = createAction(
  '[Capsule] Set Capsule Backup Restore Initiated',
  props<{ spaceId: string; capsuleId: string; backupId: string }>()
);
export const startCapsuleBackupRestoreSuccess = createAction(
  '[Capsule] Set Capsule Backup Restore Success',
  props<{ restore: any }>()
);
export const startCapsuleBackupRestoreFailed = createAction(
  '[Capsule] Set Capsule Backup Restore Failed',
  props<{ error: Error }>()
);

// fetch capsule restores
export const fetchCapsuleRestores = createAction(
  '[Capsule] Fetch Capsule restores Initiated',
  props<{ spaceId: string; capsuleId: string }>()
);
export const fetchCapsuleRestoresSuccess = createAction(
  '[Capsule] Fetch Capsule restores Success',
  props<{ restores: Restore[]; capsuleId: string }>()
);
export const fetchCapsuleRestoresFailed = createAction(
  '[Capsule] Fetch Capsule restores Failed',
  props<{ error: Error }>()
);

// fetch backups
export const fetchCapsuleBackups = createAction(
  '[Capsule] Fetch Capsule Backups',
  props<{ space: Space; capsule: Capsule }>()
);
export const fetchCapsuleBackupsSuccess = createAction(
  '[Capsule] Fetch Capsule Backups Success',
  props<{ backups: Backup[]; capsule: Capsule }>()
);
export const fetchCapsuleBackupsFailed = createAction(
  '[Capsule] Fetch Capsule Backups Failed',
  props<{ error: Error }>()
);
export const stopPollingBackups = createAction(
  '[Capsule] Stop Polling Backups'
);
export const stopPollingRestores = createAction(
  '[Capsule] Stop Polling Restores'
);

// set capsule auto backup
export const setCapsuleBackupSchedule = createAction(
  '[Capsule] Set Capsule Backup Schedule',
  props<{ space: Space; capsule: Capsule; backupSchedule: BackupSchedule }>()
);
export const setCapsuleBackupScheduleSuccess = createAction(
  '[Capsule] Set Capsule Backup Schedule Success',
  props<{ backupSchedule: BackupSchedule }>()
);
export const setCapsuleBackupScheduleFailed = createAction(
  '[Capsule] Set Capsule Backup Schedule Failed',
  props<{ error: Error }>()
);

export const fetchCapsuleBackupSchedule = createAction(
  '[Capsule] Fetch Capsule Backup Schedule',
  props<{ space: Space; capsuleId: string }>()
);
export const fetchCapsuleBackupScheduleSuccess = createAction(
  '[Capsule] Fetch Capsule Backup Schedule Success',
  props<{ backupSchedule: BackupSchedule }>()
);
export const fetchCapsuleBackupScheduleFailed = createAction(
  '[Capsule] Fetch Capsule Backup Schedule Failed',
  props<{ error: Error }>()
);

export const deleteCapsuleBackupSchedule = createAction(
  '[Capsule] Delete Capsule Backup Schedule',
  props<{ space: Space; capsule: Capsule }>()
);
export const deleteCapsuleBackupScheduleSuccess = createAction(
  '[Capsule] Delete Capsule Backup Schedule Success',
  props<{ capsuleId: string }>()
);
export const deleteCapsuleBackupScheduleFailed = createAction(
  '[Capsule] Delete Capsule Backup Schedule Failed',
  props<{ error: Error }>()
);

// fetch capsule
export const fetchCapsule = createAction(
  '[Capsule] Fetch Capsule Initiated',
  props<{ space: Space; capsuleId: string }>()
);
export const fetchCapsuleSuccess = createAction(
  '[Capsule] Fetch Capsules Success',
  props<{ space: Space; capsule: Capsule }>()
);
export const fetchCapsuleFailed = createAction(
  '[Capsule] Fetch Capsules Failed',
  props<{ error: Error }>()
);

// fetch capsules
export const fetchSpaceCapsules = createAction(
  '[Capsule] Fetch Space Capsules',
  props<{ space: Space }>()
);
export const fetchSpacesCapsules = createAction(
  '[Capsule] Fetch Spaces Capsules',
  props<{ spaces: Space[] }>()
);
export const fetchCapsulesSuccess = createAction(
  '[Capsule] Fetch Capsules Success',
  props<{ capsules: Capsule[] }>()
);
export const fetchCapsulesFailed = createAction(
  '[Capsule] Fetch Capsules Failed',
  props<{ error: Error }>()
);

// capsule usage
export const fetchCapsuleUsage = createAction(
  '[Capsule] Fetch Capsule Usage',
  props<{ capsule: Capsule; space: Space; billingPeriod?: Date }>()
);
export const fetchCapsuleUsageSuccess = createAction(
  '[Capsule] Fetch Capsule Usage Success',
  props<{ capsuleUsage: CapsuleCostsResponse }>()
);
export const fetchCapsuleUsageFailed = createAction(
  '[Capsule] Fetch Capsule Usage Failed',
  props<{ error: Error }>()
);

// set capsule auto build
export const updateCapsuleAutoBuild = createAction(
  '[Capsule] Set Capsule Auto Build',
  props<{ space: Space; capsule: Capsule }>()
);
export const updateCapsuleAutoBuildSuccess = createAction(
  '[Capsule] Set Capsule Auto Build Success',
  props<{ capsule: Capsule }>()
);
export const updateCapsuleAutoBuildFailed = createAction(
  '[Capsule] Set Capsule Auto Build Failed',
  props<{ error: Error }>()
);

// fetch capsule config
export const fetchCapsuleConfig = createAction(
  '[Capsule] Fetch Capsule Config',
  props<{ space: Space; capsule: Capsule }>()
);
export const fetchCapsuleConfigSuccess = createAction(
  '[Capsule] Fetch Capsule Config Success',
  props<{ config: ConfigDto; capsule: Capsule }>()
);
export const fetchCapsuleConfigFailed = createAction(
  '[Capsule] Fetch Capsule Config Failed',
  props<{ error: Error }>()
);

// update capsule config
export const updateCapsuleConfig = createAction(
  '[Capsule] Set Capsule Config',
  props<{ space: Space; capsule: Capsule; config: ConfigDto }>()
);
export const updateCapsuleConfigSuccess = createAction(
  '[Capsule] Set Capsule Config Success',
  props<{ config: ConfigDto; capsule: Capsule }>()
);
export const updateCapsuleConfigFailed = createAction(
  '[Capsule] Set Capsule Config Failed',
  props<{ error: Error }>()
);

// update capsule manifest
export const updateCapsuleManifest = createAction(
  '[Capsule] Set Capsule Manifest',
  props<{ space: Space; capsule: Capsule; manifest: CapsuleManifest }>()
);
export const updateCapsuleManifestSuccess = createAction(
  '[Capsule] Set Capsule Manifest Success',
  props<{ capsule: Capsule }>()
);
export const updateCapsuleManifestFailed = createAction(
  '[Capsule] Set Capsule Manifest Failed',
  props<{ error: Error }>()
);

// fetch builds
export const fetchCapsuleBuilds = createAction(
  '[Capsule] Fetch Capsule Builds',
  props<{ space: Space; capsule: Capsule }>()
);
export const fetchCapsuleBuildsSuccess = createAction(
  '[Capsule] Fetch Capsule Builds Success',
  props<{ builds: Build[]; capsule: Capsule }>()
);
export const fetchCapsuleBuildsFailed = createAction(
  '[Capsule] Fetch Capsule Builds Failed',
  props<{ error: Error }>()
);

// update capsule description
export const updateCapsuleDescription = createAction(
  '[Capsule] Update Capsule Description',
  props<{ space: Space; capsule: Capsule; description: string }>()
);
export const updateCapsuleDescriptionSuccess = createAction(
  '[Capsule] Update Capsule Description Success',
  props<{ space: Space; capsule: Capsule }>()
);
export const updateCapsuleDescriptionFailed = createAction(
  '[Capsule] Update Capsule Description Failed',
  props<{ error: Error }>()
);

// update capsule products
export const updateCapsuleProducts = createAction(
  '[Capsule] Update Capsule Products',
  props<{ space: Space; capsule: Capsule; products: ProductRequestDto }>()
);
export const updateCapsuleProductsSuccess = createAction(
  '[Capsule] Update Capsule Products Success',
  props<{ space: Space; capsule: Capsule; products: ProductRequestDto }>()
);
export const updateCapsuleProductsFailed = createAction(
  '[Capsule] Update Capsule Products Failed',
  props<{ error: Error }>()
);

// set capsule repo and branch
export const setCapsuleRepo = createAction(
  '[Capsule] Set Capsule Repo Initiated',
  props<{ space: Space; capsuleId: string; branch: string; repo: Repo }>()
);
export const setCapsuleRepoSuccess = createAction(
  '[Capsule] Set Capsule Repo Success',
  props<{
    space: Space;
    capsuleId: string;
    repo: CapsuleApiRepo;
  }>()
);
export const setCapsuleRepoFailed = createAction(
  '[Capsule] Set Capsule Repo Failed',
  props<{ error: Error }>()
);

// remove capsule
export const removeCapsule = createAction(
  '[Capsule] Remove Capsule Submitted',
  props<{ capsule: Capsule; space: Space }>()
);
export const removeCapsuleSuccess = createAction(
  '[Capsule] Remove Capsule Successful',
  props<{ capsule: Capsule; space: Space }>()
);
export const removeCapsuleFailed = createAction(
  '[Capsule] Remove Capsule Failed',
  props<{ error: Error }>()
);

// capsule Create
export const capsuleCreateSuccess = createAction(
  '[Capsule] Capsule Create Success',
  props<{ capsuleId: string; space: Space }>()
);

// set capsule ingress enabled
export const setCapsuleIngressEnabled = createAction(
  '[Capsule] Set Capsule Ingress Enabled Initiated',
  props<{ space: Space; capsuleId: string; enabled: boolean }>()
);
export const setCapsuleIngressEnabledSuccess = createAction(
  '[Capsule] Set Capsule Ingress Enabled Success',
  props<{ space: Space; capsule: Capsule }>()
);
export const setCapsuleIngressEnabledFailed = createAction(
  '[Capsule] Set Capsule Ingress Enabled Failed',
  props<{ error: Error }>()
);

// set persistent storage capsule web dav enabled
export const setCapsuleWebDavEnabled = createAction(
  '[Capsule] Set Capsule Web Dav Enabled Initiated',
  props<{ space: Space; capsuleId: string; enabled: boolean }>()
);
export const setCapsuleWebDavEnabledSuccess = createAction(
  '[Capsule] Set Capsule Web Dav Enabled Success',
  props<{ capsule: Capsule }>()
);
export const setCapsuleWebDavEnabledFailed = createAction(
  '[Capsule] Set Capsule Web Dav Enabled Failed',
  props<{ error: Error }>()
);

export const capsuleIngressBasicAuth = createActionGroup({
  source: 'Capsule',
  events: {
    'Enable Basic Auth On Ingress': props<{
      space: Space;
      capsuleId: string;
    }>(),
    'Enable Basic Auth On Ingress Success': props<{
      space: Space;
      capsule: Capsule;
    }>(),
    'Enable Basic Auth On Ingress Failed': props<{ error: Error }>(),
    'Disable Basic Auth On Ingress': props<{
      space: Space;
      capsuleId: string;
    }>(),
    'Disable Basic Auth On Ingress Success': props<{
      space: Space;
      capsule: Capsule;
    }>(),
    'Disable Basic Auth On Ingress Failed': props<{ error: Error }>(),
  },
});
